import React from 'react';

export const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <div className="privacy-policy-content">
        <h1>Privacy Policy</h1>
        <p>
          Your privacy is important to us. This privacy policy explains how we collect, use, and protect your
          personal information when you use our website and services.
        </p>

        <h2>1. Information We Collect</h2>
        <p>
          We may collect the following types of information:
          <ul>
            <li>Personal identification information (Name, email address, phone number, etc.)</li>
          </ul>
        </p>

        <h2>2. How We Use Your Information</h2>
        <p>
          We use the collected information for:
          <ul>
            <li>Providing and improving our services</li>
            <li>Responding to inquiries and customer support requests</li>
          </ul>
        </p>

        <h2>3. How We Protect Your Information</h2>
        <p>
          We implement a variety of security measures to ensure the safety of your personal information,
          including encryption, secure servers, and regular security audits.
        </p>

        <h2>4. Third-Party Sharing</h2>
        <p>
          We do not sell or trade your personal information to third parties. However, we may share data with
          trusted partners for purposes such as analytics, advertising, or improving our services.
        </p>

        <h2>5. Your Data Rights</h2>
        <p>
          You have the right to:
          <ul>
            <li>Access, update, or delete your personal information</li>
            <li>Withdraw consent for data processing</li>
            <li>File a complaint with a data protection authority</li>
          </ul>
        </p>

        <h2>6. Changes to This Policy</h2>
        <p>
          We may update this policy periodically. Any changes will be posted on this page with the updated
          date.
        </p>

        <p className="last-updated">Last updated: 02/12/2024</p>

        <h2>Contact Us</h2>
        <p>
          If you have any questions about this privacy policy, please contact us at:
          <a href="mailto:support@example.com"> support@example.com</a>
        </p>
      </div>
    </div>
  );
};
