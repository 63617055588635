
import 'react-multi-carousel/lib/styles.css';
import React from 'react';
import quote from '../assets/img/reshot-icon-customer-review-5PYD2BTJAZ.svg';

export const Testimonials = () => {

    return(
      <section class="testimonials">
      <h2>What Our Clients Say</h2>
      

      <div class="testimonial testimonial-1">
          <div class="testimonial-content">
              <blockquote>
                  "This service transformed my career. The guidance was exactly what I needed to take my professional life to the next level."
              </blockquote>
              <cite>- John Doe, CEO</cite>
          </div>
          <img src={quote} className="ticon" alt='quote'/>
      </div>
  

      <div class="testimonial testimonial-2">
          <div class="testimonial-content">
              <blockquote>
                  "I gained a new perspective on my goals and how to achieve them. The support was exceptional throughout."
              </blockquote>
              <cite>- Jane Smith, Marketing Director</cite>
          </div>
          <img src={quote} className="ticon" alt='quote'/>
      </div>
  

      <div class="testimonial testimonial-3">
          <div class="testimonial-content">
              <blockquote>
                  "I learned so much about my strengths and how to leverage them. This program was invaluable."
              </blockquote>
              <cite>- Alice Johnson, HR Manager</cite>
          </div>
          <img src={quote} className="ticon" alt='quote'/>
      </div>
  

      <div class="testimonial testimonial-4">
          <div class="testimonial-content">
              <blockquote>
                  "The coaching experience was life-changing. The insights I received helped me with both personal and professional growth."
              </blockquote>
              <cite>- Michael Brown, IT Specialist</cite>
          </div>
          <img src={quote} className="ticon" alt='quote'/>
      </div>
  

      <div class="testimonial testimonial-5">
          <div class="testimonial-content">
              <blockquote>
                  "I never realized the impact that coaching could have on my day-to-day work life. Highly recommended!"
              </blockquote>
              <cite>- Sarah Lee, Entrepreneur</cite>
          </div>
          <img src={quote} className="ticon" alt='quote'/>
      </div>
  </section>
  
    )
}