import React from "react";
import { Link } from "react-router-dom"; // For navigation between pages

export const Blog = () => {
  return (
    <section>
    <div className="blog-page">
      {/* Your Navbar and Header components will be here, since you already have them */}
      <main className="main-content">
      <h2 className="blog-title">Blog Posts</h2>
        <div className="featured-posts">
          {/* Blog Post 1 */}
          <Link to="/post/1" className="featured-post-link">
          <article className="featured-post featured-post1">
            <img src="path-to-image.jpg" alt="Blog Post Image" className="featured-image" />
            <h2 className="post-title">Blog Post 1 Title</h2>
            <p className="post-date">March 20, 2024</p>
            <p className="post-excerpt">
              This is a short introduction to the first blog post. Click the title to read the full post.
            </p>
          </article>
          </Link>

          {/* Blog Post 2 */}
          <Link to="/post/2" className="featured-post-link">
          <article className="featured-post featured-post2">
            <img src="path-to-image.jpg" alt="Blog Post Image" className="featured-image" />
            <h2 className="post-title">Blog Post 2 Title</h2>
            <p className="post-date">March 19, 2024</p>
            <p className="post-excerpt">
              This is a short introduction to the second blog post. Click the title to read the full post.
            </p>
          </article>
          </Link>
        </div>
      </main>

      {/* Your Footer component will be here */}
    </div>
    </section>
  );
};
