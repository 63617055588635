// HomePage.js
import React from 'react';
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import contact from '../assets/img/contactnob.PNG';
import safe from '../assets/img/safe.svg';
import target from '../assets/img/target.svg';
import graph from '../assets/img/graph.svg';
import medal from '../assets/img/medal.svg';
import book from '../assets/img/book.svg';
import portrait from '../assets/img/portrait.jpg';

export const HomePage = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1050 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1050, min: 768 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1
    }
  };
  const features = [
    { heading: "Certified Expertise", text: "With extensive certifications and experience, I am fully dedicated to guiding you toward achieving your goals with confidence and care.", icon: medal },
    { heading: "Personalised Strategies", text: "Your journey is unique. I’ll customise our sessions to meet your individual needs, ensuring a tailored approach that works for you.", icon: target },
    { heading: "Science-Backed Methods", text: "Using evidence-based practices proven by research, I help you achieve meaningful, lasting progress that transforms your life.", icon: book },
    { heading: "Visible Results", text: "I focus on empowering you with practical tools and strategies to create measurable changes that last well beyond our sessions.", icon: graph },
    { heading: "Safe and Supportive Space", text: "Feel heard, understood, and valued in a judgment-free environment where you can freely share your thoughts and experiences.", icon: safe },
  ];

  const [visibleIndexes, setVisibleIndexes] = useState([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const index = parseInt(entry.target.dataset.index, 10);
            setVisibleIndexes((prev) => [...new Set([...prev, index])]);
          }
        });
      },
      { threshold: 0.5 }
    );
    

    const elements = document.querySelectorAll(".feature");
    elements.forEach((el, index) => {
      el.dataset.index = index;
      observer.observe(el);
    });
    

    return () => {
      elements.forEach((el) => observer.unobserve(el));
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("fade-in");
          }
        });
      },
      { threshold: 0.5 }
    );

    // Select both the image container and content elements
    const elements = document.querySelectorAll(
      ".therapist-image-container, .therapist-content, .service-cards, .review-bx"
    );

    elements.forEach((el) => observer.observe(el));

    return () => {
      elements.forEach((el) => observer.unobserve(el));
    };
  }, []);

  return (
    <div className="homepage">
      
      {/* Welcome Section */}
      <section className="welcome-section">
        <div className="welcome-image"></div>
        <div className="welcome-text welcome-text1">
          <h1>C.C Coaching U.K Ltd.</h1>
          <p>**Capitvating headline</p>
        </div>
      </section>

      {/* Meet Your Therapist */}
      <section className="meet-therapist">
        <div className="therapist-image-container">
          <img src={portrait} alt="Therapist" className="therapist-image"/>
        </div>
        <div className="therapist-content">
          <h2>Meet Your Personal Coach</h2>
          <p>Hello, my name is Carolyn Cowperthwaite and I am a trained Executive Coach. I help managers with obtaining promotion and assist them to lead with confidence, build high performing teams whilst achieving a positive work/life balance. My passion is helping others to overcome their challenges to create the life they want.</p>
        </div>
      </section>

      <section className='journey'>
        <div>
          <h2>Leading with Purpose</h2>
          <p><strong>My mission:</strong> Helping doctors and senior professionals to lead with confidence in order to be happy both personally and professionally.</p>

          <h2>My Journey</h2>
          <p>
            With over three decades of experience in healthcare, including 12 years in senior management, I've had the privilege of working in various roles, from bedside nursing to executive leadership. I've witnessed firsthand the challenges and rewards of a career in healthcare.
          </p>
          <p>
            My passion for leadership and well-being led me to become an executive coach, empowering doctors, senior professionals, and teams from various professional sectors to excel in their roles and achieve a fulfilling work-life balance. I've helped countless clients:
          </p>
          <ul>
            <li>Overcome burnout and regain their work-life balance.</li>
            <li>Enhance their leadership skills and influence.</li>
            <li>Improve their communication and interpersonal skills.</li>
            <li>Build stronger, more effective teams.</li>
            <li>Increase their confidence and self-belief.</li>
            <li>Navigate complex organizational challenges.</li>
            <li>Achieve greater job satisfaction and fulfillment.</li>
          </ul>
          <p>
            By combining my leadership expertise, coaching skills, and deep understanding of the complexities of leadership, I provide tailored support to help you achieve your goals.
          </p>
          <p>Let's work together to unlock your potential and create a fulfilling career.</p>
        </div>
      </section>

      {/* Why Choose Me */}
      <section className="services">
        <h2>Services Offered</h2>
        <div
            className="service-cards"
          >
          <div className="service-card">
            <h3>Individual Coaching</h3>
            <p>One-on-one coaching sessions tailored to your specific goals and challenges, 
            helping you achieve personal and professional growth.</p>
          </div>
          <div className="service-card">
            <h3>Team Coaching</h3>
            <p>Designed to foster collaboration, enhance communication, and improve team dynamics 
            for greater organisational success.</p>
          </div>
          <div className="service-card">
            <h3>Executive Coaching</h3>
            <p>Focused on helping leaders enhance their strategic thinking, decision-making, and 
            leadership skills to drive organisational growth.</p>
          </div>
        </div>
      </section>

      {/* Why Choose Me */}
      <section className="why-choose">
      <h2>Why Choose Me?</h2>
      <div className="features-section">
        {features.map((feature, index) => (
          <div
            key={index}
            className={`feature ${
              visibleIndexes.includes(index) ? "fade-in" : ""
            }`}
          >
            <img src={feature.icon} alt={feature.heading} className="ficon" />
            <h3 className="heading">{feature.heading}</h3>
            <p className="text">{feature.text}</p>
          </div>
        ))}
      </div>
    </section>

      <section className="review" id="reviews">
            <Row>
                <Col>
                <div className="review-bx">
                    <h2>
                        Testimonials
                    </h2>
                    <Carousel responsive={responsive} infinite={true} className="review-slider">
                        <div className="item">
                          <p>**Review1
                          </p>
                            <h5>**Name1</h5>
                        </div>
                        <div className="item">
                            <p>**Review2</p>
                            <h5>**Name2</h5>
                        </div>
                        <div className="item">
                            <p>**Review3</p>
                            <h5>**Name3</h5>
                        </div>
                    </Carousel>
                </div>
                </Col>
            </Row>
    </section>
      {/* Contact Us */}
      <section className="home-contact">
  <div className="contact-wrapper">
    <div className="contact-content contact-link">
      <h2>Contact Us</h2>
      <p>
        Ready to take the next step? Reach out to us today and let us help you achieve your goals.
      </p>
      <a href="/contact">
      <button type="submit">
        <span>Get In Touch</span>
      </button>
      </a>
    </div>
    <div className="contact-logo">
      <a href="/contact">
        <img src={contact} alt="Contact" />
      </a>
    </div>
  </div>
</section>
    </div>
  );
};