import React from 'react';
import leadership from '../assets/img/leader.svg';
import growth from '../assets/img/growth.png';
import communication from '../assets/img/discussion.png';
import balance from '../assets/img/balance.png';

export const Services = () => {
    return (
      <section className='coaching' id='coaching'>
        <div className="coaching-page">
            {/* About Coaching Section */}
            <section id="about-coaching" className="about-coaching">
                <div className="container">
                    <h2>Transform Your Career with Professional Coaching</h2>
                    <p>
                        At <strong>C.C Coaching U.K</strong>, we **services intro text**
                    </p>
                </div>
            </section>

            {/* Why Coaching Section */}
            <section id="why-coaching" className="why-coaching">
                <h2>Why Choose Coaching?</h2>
                <div className="benefits">
                    <div className="benefit">
                        <img src={leadership} alt="Leadership Icon" className="ficon"/>
                        <h3>Leadership Development</h3>
                        <p>Enhance your ability to inspire and lead effectively.</p>
                    </div>
                    <div className="benefit">
                        <img src={communication} alt="Communication Icon" className="ficon"/>
                        <h3>Communication Skills</h3>
                        <p>Master the art of clear, impactful communication.</p>
                    </div>
                    <div className="benefit">
                        <img src={growth} alt="Growth Icon" className="ficon"/>
                        <h3>Career Growth</h3>
                        <p>Identify opportunities and strategize for long-term success.</p>
                    </div>
                    <div className="benefit">
                        <img src={balance} alt="Balance Icon" className="ficon"/>
                        <h3>Work-Life Balance</h3>
                        <p>Achieve harmony between your personal and professional goals.</p>
                    </div>
                </div>
            </section>

            {/* Types of Coaching Services Section */}
            <section id="coaching-services" className="coaching-services">
                <h2>Explore Our Coaching Services</h2>
                <div className="services-grid">
                    {/* Individual Coaching */}
                    <div className="service">
                        <h3>Individual Coaching</h3>
                        <p>
                            One-on-one coaching sessions tailored to your specific goals and challenges, 
                            helping you achieve personal and professional growth.
                        </p>
                    </div>

                    {/* Team Coaching */}
                    <div className="service">
                        <h3>Team Coaching</h3>
                        <p>
                            Designed to foster collaboration, enhance communication, and improve team dynamics 
                            for greater organisational success.
                        </p>
                    </div>

                    {/* Executive Coaching */}
                    <div className="service">
                        <h3>Executive Coaching</h3>
                        <p>
                            Focused on helping leaders enhance their strategic thinking, decision-making, and 
                            leadership skills to drive organisational growth.
                        </p>
                    </div>
                </div>
            </section>

            {/* How It Works Section */}
            <section id="how-it-works" className="how-it-works">
                <h2>How It Works</h2>
                <ul>
                    <li>
                        <h3>Step 1: Schedule a Consultation</h3>
                        <p>We’ll discuss your goals and challenges in a one-on-one session.</p>
                    </li>
                    <li>
                        <h3>Step 2: Tailored Coaching Plan</h3>
                        <p>Receive a customized program designed to meet your unique needs.</p>
                    </li>
                    <li>
                        <h3>Step 3: Implement and Grow</h3>
                        <p>Apply new strategies and skills to achieve measurable results.</p>
                    </li>
                </ul>
            </section>

            {/* Testimonials Section */}
            <section id="services-testimonials" className="services-testimonials">
                <h2>What Our Clients Say</h2>
                <div className="services-testimonial">
                    <blockquote>
                        "Thanks to ProCoach, I’ve learned how to manage my time effectively and lead with confidence."
                    </blockquote>
                    <cite>- Sarah Johnson, Marketing Manager</cite>
                </div>
                <div className="services-testimonial">
                    <blockquote>
                        "The coaching sessions were transformative. I landed my dream job in just six months!"
                    </blockquote>
                    <cite>- Tom Williams, IT Specialist</cite>
                </div>
            </section>

            {/* Call to Action */}
            <section id="call-to-action" className="call-to-action">
                <h2>Ready to Take the Next Step?</h2>
                <p>Join the countless professionals who have unlocked their potential with <strong>C.C Coaching U.K</strong>.</p>
                <a href="/contact">
                <button type="submit">
                <span>Get In Touch</span>
                </button>
                </a>
            </section>
        </div>
      </section>
    );
};
