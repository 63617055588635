import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Header } from './components/Header';
import { NavBar } from './components/NavBar';
import { Contact } from './components/Contact';
import { Services } from './components/Services';
import { useEffect } from 'react';
import { HomePage } from './components/Homepage';
import { Packages } from './components/Packages';
import { Blog } from './components/Blog';
import { BlogPost } from './components/Blogpost'
import { Testimonials } from './components/Testimonials';
import { FAQ } from './components/FAQ';
import { Footer } from './components/Footer';
import { NotFoundPage } from './components/404';
import { InternalServerErrorPage } from './components/500';
import { PrivacyPolicy } from './components/policy';



function App() {
  useEffect(() => {
    const sections = document.querySelectorAll('section');
    
    const observer = new IntersectionObserver(
        (entries, observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add("visible");
                    observer.unobserve(entry.target);
                }
            });
        },
        { threshold: 0.1, rootMargin: "0px 0px -50px 0px" }
    );

    sections.forEach((section) => observer.observe(section));
    
    return () => observer.disconnect(); // Cleanup observer

}, []);



  return (
    <div className="App">
      {/* Router wraps all navigable components */}
      <Router>
        {/* NavBar should be outside Routes so it appears on all pages */}
        <Header />
        <NavBar />

        {/* Routes for different pages */}
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/services" element={<Services />} />
          <Route path="/testimonials" element={<Testimonials />} />  
          <Route path="/blog" element={<Blog />} /> 
          <Route path="/contact" element={<Contact />} />
          <Route path="/faq" element={<FAQ />} /> 
          <Route path="/packages" element={<Packages />} />
          <Route path="/post/:postId" element={<BlogPost />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="*" element={<NotFoundPage />} /> {/* Catch-all route */}
          <Route path="/500" element={<InternalServerErrorPage />} />
        </Routes>

        {/* Footer will also appear on all psages */}
      </Router>
      <Footer />
    </div>
  );
}

export default App;
