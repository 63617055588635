import React from 'react';

export const InternalServerErrorPage = () => {
  return (
    <div className="not-found-container">
      <h1 className="error-code">500</h1>
      <p className="error-message">
        Oops! Something went wrong on our end. Please try again later.
      </p>
      <a href="/">
      <button type="submit">
        <span>Go Back Home</span>
      </button>
      </a>
    </div>
  );
};
