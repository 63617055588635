
import React from 'react';

export const Packages = () => {
    const packages = [
        {
            title: "Individual Coaching",
            description: "Personalized one-on-one sessions to help you achieve your career and personal growth goals.",
            price: "$150/session",
            features: [
                "Personalized coaching plan",
                "Weekly one-hour sessions",
                "Email support between sessions",
                "Goal tracking and accountability",
            ],
        },
        {
            title: "Team Coaching",
            description: "Strengthen team dynamics and improve collaboration with our specialized team coaching sessions.",
            price: "$1200/month",
            features: [
                "Customized team assessments",
                "Bi-weekly group coaching",
                "Conflict resolution strategies",
                "Leadership development for team leaders",
            ],
        },
        {
            title: "Executive Coaching",
            description: "Tailored coaching for executives to enhance leadership skills and drive organizational success.",
            price: "$500/session",
            features: [
                "Executive-level goal setting",
                "Confidential one-on-one sessions",
                "Strategic decision-making support",
                "Work-life balance techniques",
            ],
        },
    ];

    return (
      <div>
      <section>
        <div className="packages-container">
            <h1 className="packages-title">Coaching Packages</h1>
            <p className="packages-subtitle">Choose the coaching package that suits your needs and start your journey to success today.</p>
            <div className="packages-grid">
                {packages.map((pkg, index) => (
                    <div key={index} className={`package-card package-card${index + 1}`}>
                        <h2 className="package-title">{pkg.title}</h2>
                        <p className="package-description">{pkg.description}</p>
                        <p className="package-price">{pkg.price}</p>
                        <ul className="package-features">
                            {pkg.features.map((feature, i) => (
                                <li key={i}>{feature}</li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </div>
        </section>
        <section id="call-to-action" className="call-to-action">
          <h2>Ready to Take the Next Step?</h2>
          <p>Join the countless professionals who have unlocked their potential with <strong>C.C Coaching U.K</strong>.</p>
          <a href="/contact">
            <button type="submit">
            <span>Get In Touch</span>
            </button>
          </a>
        </section>
        </div>
    );
};
