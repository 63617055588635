import { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import logo from '../assets/img/IMG_3599.PNG';
import React from 'react';

export const NavBar = () => {
    const [activeLink, setActiveLink] = useState('home');
    const [scrolled, setScrolled] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isClosing, setIsClosing] = useState(false);

    useEffect(() => {
        const onScroll = () => {
            setScrolled(window.scrollY > 50);
        };
        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, []);

    const onUpdateActiveLink = (value) => {
        setActiveLink(value);
        setIsMenuOpen(false);
        setIsClosing(false);
    };

    const toggleMenu = () => {
        if (isMenuOpen) {
            setIsClosing(true);
            setTimeout(() => {
                setIsMenuOpen(false);
                setIsClosing(false);
            }, 500); // Match the duration of the slideUp animation
        } else {
            setIsMenuOpen(true);
        }
    };

    return (
        <>
            <Navbar expand="lg" className={scrolled ? "scrolled" : ""}>
                <Container>
                    <Navbar.Brand href="/">
                        <img className="logo" src={logo} alt="Logo" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={toggleMenu}>
                        <span className="navbar-toggler-icon"></span>
                    </Navbar.Toggle>
                    <Navbar.Collapse id="basic-navbar-nav" className="d-none d-lg-flex">
                        <Nav className="ml-auto navbarbuttons">
                            <Nav.Link href="/" className={activeLink === 'home' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('home')}>Home</Nav.Link>
                            <Nav.Link href="/services" className={activeLink === 'services' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('services')}>Services</Nav.Link>
                            <Nav.Link href="/testimonials" className={activeLink === 'testimonials' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('testimonials')}>Testimonials</Nav.Link>
                            <Nav.Link href="/blog" className={activeLink === 'blog' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('blog')}>Blog</Nav.Link>
                            <Nav.Link href="/contact" className={activeLink === 'contact' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('contact')}>Contact</Nav.Link>
                            <Nav.Link href="/faq" className={activeLink === 'faqs' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('faqs')}>FAQs</Nav.Link>
                            <Nav.Link href="/packages" className={activeLink === 'packages' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('packages')}>Packages</Nav.Link>
                        </Nav>
                        
                    </Navbar.Collapse>
                </Container>
            </Navbar>

            {(isMenuOpen || isClosing) && (
                <div className={`dropdown-container d-lg-none ${isClosing ? 'closing' : ''}`}>
                    <div className="me-auto navbar-nav">
                        <Nav.Link href="/" className="dropdown-button" onClick={() => onUpdateActiveLink('home')}>Home</Nav.Link>
                        <Nav.Link href="/services" className="dropdown-button" onClick={() => onUpdateActiveLink('coaching')}>Services</Nav.Link>
                        <Nav.Link href="/testimonials" className="dropdown-button" onClick={() => onUpdateActiveLink('testimonials')}>Testimonials</Nav.Link>
                        <Nav.Link href="/blog" className="dropdown-button" onClick={() => onUpdateActiveLink('blog')}>Blog</Nav.Link>
                        <Nav.Link href="/contact" className="dropdown-button" onClick={() => onUpdateActiveLink('contact')}>Contact</Nav.Link>
                        <Nav.Link href="/faq" className="dropdown-button" onClick={() => onUpdateActiveLink('faqs')}>FAQs</Nav.Link>
                        <Nav.Link href="/packages" className="dropdown-button" onClick={() => onUpdateActiveLink('packages')}>Packages</Nav.Link>
                    </div>
                </div>
            )}
        </>
    );
};
