import React from "react";
import { useParams } from "react-router-dom"; // To capture the post ID from the URL

export const BlogPost = () => {
  const { postId } = useParams(); // Retrieve the post ID from the URL

  // Mock data for blog posts
  const posts = {
    1: {
      imageUrl: "path-to-blog1-image.jpg",
      title: "The Benefits of Mindfulness",
      date: "March 20, 2024",
      content:
        "Mindfulness is the practice of being present in the moment. It helps reduce stress, increase focus, and improve emotional regulation. In this blog post, we’ll explore how mindfulness can transform your daily life and enhance your mental well-being.",
    },
    2: {
      imageUrl: "path-to-blog2-image.jpg",
      title: "Top 5 Time Management Techniques",
      date: "March 19, 2024",
      content:
        "Time management is essential for productivity. In this post, we’ll discuss techniques like the Pomodoro method, prioritization with Eisenhower’s matrix, and the importance of setting realistic goals.",
    },
  };

  // Get the specific post data based on postId
  const postData = posts[postId];

  // Handle case where postId is not found
  if (!postData) {
    return (
      <section>
      <div className="single-post">
        <main className="post-content">
          <div className="post-body">
            <h2>Post Not Found</h2>
            <p>The blog post you are looking for does not exist.</p>
          </div>
        </main>
      </div>
      </section>
    );
  }

  return (
      <div className="single-post">
        <main className="post-content">
          <div className="post-header">
            <img src={postData.imageUrl} alt={postData.title} className="post-image" />
            <h2 className="post-title">{postData.title}</h2>
            <p className="post-date">{postData.date}</p>
          </div>
          <div className="post-body">
            <p>{postData.content}</p>
          </div>
        </main>
      </div>
  );
};
