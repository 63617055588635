import React, { useState } from 'react';

export const FAQ = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleFAQ = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const faqData = [
        {
            question: "What is your return policy?",
            answer: "We offer a 30-day return policy on all items. Please ensure the item is unused and in its original packaging.",
        },
        {
            question: "How long does shipping take?",
            answer: "Shipping typically takes 3-5 business days, depending on your location. Expedited shipping options are also available.",
        },
        {
            question: "Do you offer customer support?",
            answer: "Yes, we offer 24/7 customer support via email, chat, and phone. Feel free to contact us anytime.",
        },
        {
            question: "Can I cancel or modify my order?",
            answer: "Orders can be modified or canceled within 24 hours of placing them. After this period, we start processing your order.",
        },
        {
            question: "Do you ship internationally?",
            answer: "Yes, we ship to over 50 countries. International shipping rates and delivery times may vary based on your location.",
        },
    ];

    return (
      <section>
        <div className="faqs-container">
            <h1 className="faqs-title">Frequently Asked Questions</h1>
            <div className="faqs-column">
                {faqData.map((faq, index) => (
                    <div
                        key={index}
                        className={`faq-card faq-card${index + 1} ${activeIndex === index ? 'active' : ''}`}
                    >
                        <div
                            className="faq-question-container"
                            onClick={() => toggleFAQ(index)}
                        >
                            <div className="faq-question">
                                {faq.question} </div>
                                <span className="faq-icon">
                                    {activeIndex === index ? '-' : '+'}
                                </span>
                            
                        </div>
                        {activeIndex === index && (
                            <div className="faq-answer">
                                {faq.answer}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
        </section>
    );
};
